<template>
  <div>
    <solution-header
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :call-us-btn="callUsBtn"
        :title="title"
    />
<!--    <ImageList :images="images"></ImageList>-->
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
               :title="`行业现状和痛点`" :en_title="`INDUSTRY STATUS & PAIN POINTS`"></Paragraph>
    <Titlestrlist :datalist="datalist" style="margin-bottom: 6rem"></Titlestrlist>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
               :title="`方案的优势与价值`" :en_title="`ADVANTAGES AND VALUE OF THE SOLUTION`"></Paragraph>
    <div style="width: 70%;margin-left: 15%">
    <Lettertitleblock :datalist="datalistx"
                      style="display: flex;flex-direction: row;justify-content:center ;
                        width: 100%"></Lettertitleblock>
    </div>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
               :title="`解决方案应用场景`" :en_title="`SOLUTION APPLICATION SCENARIOS`"></Paragraph>
    <div style="width: 80%;margin-left: 10%">
        <div style="width: 100%;height: 30rem;display: flex;flex-direction: row">
          <div style="width: 50%;height: 100%;">
            <div style="height: 25%;display: flex;
            flex-direction: column;justify-content: flex-end ;line-height: 0.5rem;">
              <p style="margin:0;">企业发行并</p>
              <p>上架销售数字卡券</p>
            </div>
            <div style="height: 50%;display: flex;flex-direction: row;
               ">
              <div style="width: 33.3%;display: flex;
            flex-direction: column;align-items: flex-end;
            justify-content: center ;line-height: 0.5;">
                 <p style="margin: 0">用户向</p>
                 <p>企业购买</p>
              </div>
              <div style="width: 33.3%;position: relative
              ;">
                <div style="display: flex;flex-direction: column;align-items: center">
                  <div style="position: absolute;background-color: gray;
                      height: 0.3rem;width: 15%;left: 8%;top:2.15rem
                    "></div>
                  <img src="@/assets/image/contentsvg/house.svg"
                       style="width: 4rem;height:4rem;position: absolute;
                     ">
                  <div style="position:absolute;right: 22%;top:2rem"
                       class="triangle-left"></div>
                  <div style="position: absolute;background-color: gray;
                      height: 0.3rem;width: 15%;right: 8%;top:2.15rem;
                    "></div>
                </div>
                <div style="position: absolute;height: 8rem;top:2.3rem;left: 8%;
                width: 0.3rem;background-color: gray;
                "></div>
                <div class="triangle-down" style="position: absolute;
                 top:10rem;left: 7%;
                 "></div>
                <div style="position: absolute;height: 8rem;top:2.3rem;right: 8%;
                width: 0.3rem;background-color: gray;
                "></div>
                <div style="position: absolute;bottom: 0;width: 100%;height: 4rem">
                  <div style="position: relative">
                    <img src="@/assets/image/contentsvg/people.svg"
                         style="position: absolute;left:0;
                         width: 4rem;height:4rem;">
                    <div style="position:absolute;left: 37%;top:2rem"
                         class="triangle-left"></div>
                    <div style="position: absolute;background-color: gray;
                      height: 0.3rem;width: 20%;left: 40%;top:2.15rem
                    "></div>
                    <div style="position:absolute;right: 37%;top:2rem"
                         class="triangle-right"></div>
                    <img src="@/assets/image/contentsvg/people.svg"
                         style="position: absolute;width: 4rem;
                         right: 0;
                         height:4rem;">
                  </div>
                </div>
              </div>
              <div style="width: 33.3%;display: flex;
            flex-direction: column;align-items: start;
            justify-content: center ;line-height: 0.5rem;">
                <p style="margin: 0">线上线下</p>
                <p>多种核销方式</p>
              </div>
            </div>
            <div style="height: 25%;line-height: 0.5rem">
              <p>用户市场流通</p>
              <p>转赠/交易</p>
            </div>
          </div>
          <div style="width:50%;">
            <Titlenewlinelist :datalist="datalistf" style="margin-bottom: 6rem"></Titlenewlinelist>
          </div>
        </div>
    </div>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/note.svg`)"
               :title="`案例与伙伴`" :en_title="`CASES & PARTNERS`"></Paragraph>
    <div style="width: 80%;margin-left: 10%;margin-bottom: 6rem;
    display:flex; flex-direction: row;justify-content: center;
    font-weight: bold">
        <div style="margin-right: 4rem">
          <img src="@/assets/image/solution/digital/bailian.png"
               style="height: 6rem">
          <div style="margin-top: 1rem">百联</div>
        </div>
      <div style="margin-right: 4rem">
        <img src="@/assets/image/solution/digital/jinhua.png"
             style="height: 6rem">
        <div style="margin-top: 1rem">金华火腿</div>
      </div>
      <div style="margin-right: 4rem">
        <img src="@/assets/image/solution/digital/guan.png"
             style="height: 6rem">
        <div style="margin-top: 1rem">冠生园</div>
      </div>
      <div style="margin-right: 4rem">
        <img src="@/assets/image/solution/digital/luji.png"
             style="height: 6rem">
        <div style="margin-top: 1rem">绿迹农业</div>
      </div>
    </div>
  </div>
</template>

<script>
import SolutionHeader from '@/components/common/SolutionHeader';
import Paragraph from '@/components/chain/basic/common/paragraph';
// import ImageList from '@/components/common/ImageList';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';
import Titlenewlinelist from '@/components/chain/basic/common/titlenewlinelist';

export default {
  name: 'BaaSLeaflet',
  components: {
    SolutionHeader,
    // ImageList,
    Paragraph,
    Titlestrlist,
    Lettertitleblock,
    Titlenewlinelist,
  },
  data() {
    return {
      datalistf: [
        { title: '精准获客', content: '以自由交易和流通的手段，筛选有真实需求的用户' },
        { title: '复购', content: '以奖励的形式发放数字权益，增加消费者的复购率' },
        { title: ' 传播裂变', content: '以转赠和转卖的形式，开拓数字卡券流通和传播的渠道' },
      ],
      datalistx: [
        {
          title: '发行流程简易',
          imgsrc: 'A',
          list: [
            '基于数字资产发行系统，',
            '企业通过网页操作即可一站式',
            '完成数字卡券的',
            '发行、上架出售',
          ],
        }, {
          title: '数字卡券可流通',
          imgsrc: 'B',
          list: [
            '基于区块链智能合约，',
            '所有数字卡券均可完成',
            '卡券持有者之间的流通，',
            '如赠送或交易，',
            '使最终核销的用户变得更加精准，',
            '提高核销率',
          ],
        }, {
          title: '交易可追溯',
          imgsrc: 'C',
          list: [
            '每一张数字卡券从',
            '发行、上架出售、转赠、转卖、最终到核销的',
            '整个生命周期的流转数据',
            '都记录在链上公开可查询，',
            '提高取证能力，保障消费者权益。',
          ],
        }, {
          title: '数据可实时报备',
          imgsrc: 'D',
          list: [

          ],
        },
      ],
      datalist: [
        { title: '>  ', content: '实物载体加记账方式发行的传统会员卡券产品，效率低、流程复杂，出错率和成本高，电子化和数字化是必然的趋势' },
        { title: '>  ', content: '常规卡券发行系统相对封闭，导致卡券流通交易成本高，但核销率低' },
        { title: '> ', content: '常规卡券无法很好的满足用户对于安全性的诉求' },
        { title: '> ', content: '政策强力推行预付卡监管，但现有发卡系统对接监管和数据上报的技术及运营成本过高' },
      ],
      title: '数字消费资产解决方案',
      body:
          '消费卡券数字资产业务系统，融合了普通电子卡券的低成本发行、数字营销等优势和实体卡券可交易流通的特性，具备将消费卡券线下业务完整的复刻到线上的能力。',
      image: require('@/assets/image/solution/digital/head.svg'),
      showDetailBtn: true,
      callUsBtn: true,
      images: [
        require('@/assets/image/solution/digital/23-01.jpg'),
        require('@/assets/image/solution/digital/23-02.jpg'),
        require('@/assets/image/solution/digital/23-03.jpg'),
        require('@/assets/image/solution/digital/23-04.jpg'),
      ],
    };
  },
};
</script>

<style scoped>

.triangle-up{
  display:inline-block;
  width:0;
  height:0;
  border-left:0.3rem solid transparent;
  border-right: 0.3rem solid transparent;
  border-bottom:0.5rem solid gray;}
.triangle-down {
  display:inline-block;
  width:0;
  height:0;
  border-left:0.3rem solid transparent;
  border-right: 0.3rem solid transparent;
  border-top:0.5rem solid gray;}
.triangle-left {
  display:inline-block;
  width:0;
  height:0;
  border-top: 0.3rem solid transparent;
  border-right: 0.5rem solid gray;
  border-bottom: 0.3rem solid transparent;}
.triangle-right{
  display:inline-block;
  width:0;
  height:0;
  border-top: 0.3rem solid transparent;
  border-left: 0.5rem solid gray;
  border-bottom: 0.3rem solid transparent;}
</style>
