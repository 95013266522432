<template>
  <div style="margin-top: 2rem;margin-left: 10%;margin-right: 10%">
    <ul style="list-style: none;padding: 0">
      <li v-for="item in datalist" :key="item.index"
          class="hf" style="text-align: left;
            color: #333333;font-size: 1.4rem;
            font-weight: 400;font-family: PingFang SC Medium, PingFang SC Medium-Medium;
            letter-spacing: 0.2rem;line-height: 2rem;">
             <span style="font-weight: 400;
          color: #2285ff;font-size: 1.5rem;
          margin-right: 1rem">{{ item.title }}</span>
        <p>  {{ item.content }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'titlestrlist',
  props: ['datalist'],
  methods: {},
  created() {
    const list = document.getElementsByClassName('hf');
    for (let i = 0; i < list.length; i += 1) {
      const content = list[i].innerHTML;
      const contenlist = content.split(' ');
      console.log(contenlist);
    }
  },
};
</script>

<style scoped>

/*.hf:before {*/
/*  font-weight: 400;*/
/*  color: #2285ff;*/
/*  font-size: 1.5rem;*/
/*  margin-right: 1rem;*/
/*  content: attr(data-content);*/
/*}*/
</style>
